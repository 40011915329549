import React, { Component } from 'react';
import './WedMap.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer as LeafletMap, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import L from 'leaflet';
// import Routing from './routing.js';
import {injectIntl} from 'react-intl';
import icons_loc from './img/Location.svg';
import icons_bus from './img/Bus.svg';

//const { Map: LeafletMap, TileLayer, Marker, Popup } = ReactLeaflet

const iconLocation = new L.Icon({
    iconUrl: icons_loc,
    iconSize: new L.Point(50, 50),
    popupAnchor: new L.Point(0, -25),
    className: 'leaflet-bus-icon'
});

const iconBus = new L.Icon({
    iconUrl: icons_bus,
    iconSize: new L.Point(84, 60),
    popupAnchor: new L.Point(0, -20),
    className: 'leaflet-bus-icon'
});

class WedMap extends Component {
  constructor() {
    super()
    this.state = {
      lat: 40.4886,
      lng: -3.7430,
      zoom: 15,
      isMapInit: false,
      showRoute1: false,
      showRoute2: false
    }
  }

  saveMap = map => {
	  this.map = map;
	  this.setState({
		  isMapInit: true
	  });
  };


  //~ componentDidMount() {
    //~ this.setState({
		//~ isMapInit: true
	//~ })
  //~ }

  render() {
    const position = [this.state.lat, this.state.lng];
    return (
      <LeafletMap 
        center={position} 
        zoom={this.state.zoom}
        maxZoom={18}
        minZoom={5}
        ref={this.saveMap}
      >
        <TileLayer
          //attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
          //url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
        />
        <Marker
          position={position}
          icon={ iconLocation }
          >
          <Tooltip>
            <b>El Club de Tiro</b>{/*  <br/> 19:30 - 4:30 */}
          </Tooltip>
        </Marker>
        <Marker
          position={[40.4116,-3.6881]}
          icon={ iconBus }
          >
          {/* <Popup
            onOpen={() => this.setState({showRoute1: true})}
            onClose={() => this.setState({showRoute1: false})}>
            <b>Bus Madrid</b><ul><li>Ida - 18:00</li><li>Regreso - 4:30</li></ul>
          </Popup> */}
          <Tooltip>
            <b>Bus 2 (Madrid)</b>
          </Tooltip>
        </Marker>
        <Marker
          position={[40.4581,-3.4683]}
          icon={ iconBus }
          >
          {/* <Popup
            onOpen={() => this.setState({showRoute2: true})}
            onClose={() => this.setState({showRoute2: false})}>
            <b>Bus Torrejón</b><br/> <ul><li>Ida - 18:00</li><li>Regreso - 4:30</li></ul>
          </Popup> */}
          <Tooltip>
            <b>Bus 1 (Torrejón)</b>
          </Tooltip>
        </Marker>
        {/* {this.state.showRoute1 && <Routing map={this.map} fromlat="40.4116" fromlng="-3.6881" tolat={this.state.lat} tolng={this.state.lng} />} */}
        {/* {this.state.showRoute2 && <Routing map={this.map} fromlat="40.4581" fromlng="-3.4683" tolat={this.state.lat} tolng={this.state.lng} />} */}
      </LeafletMap>
    );
  }
}

export default injectIntl(WedMap);
