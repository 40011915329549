import React, { PureComponent } from 'react';
import './Navbar.css'
import {injectIntl} from 'react-intl';

class Navicon extends PureComponent {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         change: false,
    //     }
    //     // this.toggleClass = this.toggleClass.bind(this);
    // }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     return {
    //         chan
    //     }
    // }

    // toggleClass() {
    //     this.props.onClickCbk();
    //     this.setState({ change: !this.state.change });
    // }

    render() {
        let styled = "envelope "+ (this.props.menuOpen? "change" : "")
        return (
            <div className={styled} onClick={this.props.onClickCbk}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        );
    }
}


class Navelement extends PureComponent {
  render() {
    return <a href={'#'+this.props.target} onClick={this.props.onClickCbk}>{this.props.label}</a>;
  }
}  


class Navarray extends PureComponent {
  constructor() {
    super();
    this.state = {
      showInSmallScreen: false,
      isHashChange: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(!prevState.isHashChange)
    return {
        showInSmallScreen: nextProps.showInSmallScreen,
    }
    else
    return {
        showInSmallScreen: false,
        isHashChange: false,
    }
  }

  handleHashChange = () => {
    this.setState({isHashChange: true})
  }
  componentDidMount() {
    window.addEventListener('hashchange', this.handleHashChange);
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange);
  }
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className={"Navarray " + (this.state.showInSmallScreen ? "Nav-slided" : "")}>
        <Navelement target="Inicio" label={formatMessage({id:"App.navlabel.home", defaultMessage:"Home"})} onClickCbk={this.props.onClickCbk}/>
        <Navelement target="Organ" label={formatMessage({id:"App.navlabel.orga", defaultMessage:"Event plan"})} onClickCbk={this.props.onClickCbk}/>
        <Navelement target="GuestArea" label={formatMessage({id:"App.navlabel.guestarea", defaultMessage:"Guest Area"})} onClickCbk={this.props.onClickCbk}/>
        <Navelement target="Fotos" label={formatMessage({id:"App.navlabel.pics", defaultMessage:"Pictures"})} onClickCbk={this.props.onClickCbk}/>
        <Navelement target="Accommodation" label={formatMessage({id:"App.navlabel.accommodation", defaultMessage:"Accommodation"})} onClickCbk={this.props.onClickCbk}/>
      </div>
    )
  }
}

class AppNavbarContainer extends PureComponent {
  constructor() {
    super()
    this.state = {
      show_in_small_screen: false,
    }
  }

  slideNaviMenu = () => {
    this.setState({ show_in_small_screen: !this.state.show_in_small_screen });
  }

  linkCalled = () => {
    this.setState({ show_in_small_screen: false });
    return true; // Must return true in order to allow the default <a onClick()> happen
  }

  render() {
    return (
      <div className="App-navbar-container">
        <Navarray intl={this.props.intl} showInSmallScreen={this.state.show_in_small_screen} onClickCbk={this.linkCalled}/>
        <div className="navicon">
          <Navicon menuOpen={this.state.show_in_small_screen} onClickCbk={this.slideNaviMenu}/>
        </div>
      </div>
    )
  }
}


export default injectIntl(AppNavbarContainer);
