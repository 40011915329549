import "./guestArea.css"
import React, { Component, PureComponent } from 'react';
import axios from 'axios';
import {injectIntl, FormattedMessage} from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

class Persona extends PureComponent {
  constructor() {
    super();
    this.state = {
      personNumber: 0
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      personNumber: Number(nextProps.persnum),
      personData: JSON.parse(nextProps.persdata)
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    let removestyle={cursor:"pointer"};
    return(
      <div className="persona">
        <fieldset className="persfieldset">
          <legend>
            <b><FormattedMessage id="GuestArea.form.guestHeader" defaultMessage="Guest" /> {this.state.personNumber + 1}</b>
            { (this.state.personNumber > 0) && (<span style={removestyle} onClick={() => this.props.removeButtonAction(this.state.personNumber)}
            title={formatMessage({id:"GuestArea.form.guest.remove", defaultMessage:"Remove guest"})}>&nbsp;[X]&nbsp;</span>)}
          </legend>
          <TextField
            variant="outlined"
            name="name"
            value={this.state.personData.name}
            onChange={this.props.onChangeChildData}
            placeholder={formatMessage({id:"GuestArea.form.guest.name", defaultMessage:"Name"})}
            label={formatMessage({id:"GuestArea.form.guest.name", defaultMessage:"Name"})}
            persnum={this.state.personNumber}
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 30 }}
          />
          <TextField
            variant="outlined"
            name="email"
            value={this.state.personData.email}
            onChange={this.props.onChangeChildData}
            placeholder={formatMessage({id:"GuestArea.form.guest.email", defaultMessage:"Email (optional)"})}
            label={formatMessage({id:"GuestArea.form.guest.email", defaultMessage:"Email (optional)"})}
            persnum={this.state.personNumber}
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 50 }}
          />
          <FormControl variant="outlined" persnum={this.state.personNumber} fullWidth margin="normal">
            <InputLabel htmlFor="outlined-age-native-simple">
              <FormattedMessage id="GuestArea.form.guest.transport" defaultMessage="Shuttle service" />
            </InputLabel>
            <Select
              native
              value={this.state.personData.transport}
              onChange={this.props.onChangeChildData}
              label={<FormattedMessage id="GuestArea.form.guest.transport" defaultMessage="Shuttle service" />}
              inputProps={{
                name: 'transport',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value="No">{formatMessage({ id:"GuestArea.form.guest.trOption.No", defaultMessage:"No" })}</option>
              <option value="Madrid">{formatMessage({ id:"GuestArea.form.guest.trOption.Ma", defaultMessage:"Madrid" })}</option>
              <option value="Torrejón">{formatMessage({ id:"GuestArea.form.guest.trOption.To", defaultMessage:"Torrejón" })}</option>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value="kidmenu"
                  name="food_kidsmenu"
                  checked={this.state.personData.food_kidsmenu}
                  color="primary"
                  onChange={this.props.onChangeChildData2}
                />
              }
              label={formatMessage({id:"GuestArea.form.guest.food.kidsmenu_label", defaultMessage:"Kids Menu"})}
              persnum={this.state.personNumber}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="veggie"
                  name="food_veggie"
                  checked={this.state.personData.food_veggie}
                  color="primary"
                  onChange={this.props.onChangeChildData2}
                />
              }
              label={formatMessage({id:"GuestArea.form.guest.food.veggie_label", defaultMessage:"Vegetarian"})}
              persnum={this.state.personNumber}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="intolerances"
                  name="food_intolerances"
                  checked={this.state.personData.food_intolerances}
                  color="primary"
                  onChange={this.props.onChangeChildData2}
                />
              }
              label={formatMessage({id:"GuestArea.form.guest.food.intolerances_chkbox_label", defaultMessage:"Food Intolerances"})}
              persnum={this.state.personNumber}
            />
          </FormGroup>
          <TextField
            variant="outlined"
            name="food_intolerancesdetail"
            disabled={!this.state.personData.food_intolerances}
            value={this.state.personData.food_intolerancesdetail}
            onChange={this.props.onChangeChildData}
            placeholder={formatMessage({id:"GuestArea.form.guest.food.intolerances_placeholder", defaultMessage:"Inform us here about any food intolerance"})}
            label={formatMessage({id:"GuestArea.form.guest.food.intolerances_label", defaultMessage:"Food intolerances"})}
            persnum={this.state.personNumber}
            fullWidth
            multiline
            rows={2}
            margin="normal"
            inputProps={{ maxLength: 250 }}
          />
        </fieldset>
      </div>
    )
  }
}

//not purecomponent because sparse check does not recognize changes in nested states
class GuestArea extends Component {
  constructor() {
    super();

    this.state = {
      user_uid: '',
      user_desc: '',
      serverError: false,
      login_tried: false,
      login_ok: false,
      savedisable: false,
      saveDisableTime: 0
    }

    this.submitHandler_login = this.submitHandler_login.bind(this);
    this.submitHandler_newData = this.submitHandler_newData.bind(this);
    this.onChangeUserAttendance = this.onChangeUserAttendance.bind(this);
    this.onChangeChildData = this.onChangeChildData.bind(this);
    this.onChangeChildData2 = this.onChangeChildData2.bind(this);
    this.removeInvitee = this.removeInvitee.bind(this);
    this.addInvitee = this.addInvitee.bind(this);
  }

  submitHandler_login(event){
    event.preventDefault();
    
    let data = {user_uid: this.state.user_uid};

    axios.post("/users/login", {data})
      .then((response) => {
        if(response.data.login_ok === true) {
          this.setState({
            response: response.data.text + response.data.count + JSON.stringify(response.data.user_data),
            login_tried: true,
            login_ok: response.data.login_ok,
            user_desc: response.data.user_desc,
            user_data: response.data.user_data,
            serverError: false
          });
        }
        else {
          this.setState({
            response: response.data.text,
            login_tried: true,
            login_ok: response.data.login_ok,
            user_desc: '',
            serverError: false
          });
        }
      })
      .catch(() => {
        this.setState({
          serverError: true 
        });
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  saveDisableTimeout() {
    if(this.state.saveDisableTime > 0) {
      this.setState({
        saveDisableTime: this.state.saveDisableTime - 1,
        savedisable: true
        },
        () => setTimeout(this.saveDisableTimeout.bind(this), 1000)
      )
    }
    else {
      this.setState({
        saveDisableTime: 0,
        savedisable: false
      })
    }
  }

  submitHandler_newData(event){
    event.preventDefault();
    
    const { formatMessage } = this.props.intl;
    let data = {
      user_uid: this.state.user_uid,
      newdata: this.state.user_data
    }

    axios.post("/users/", {data})
      .then((response) => {

        this.setState({
          datasaved_ok: response.data.datasaved_ok,
          savedisable: true,
          saveDisableTime: 6,
          serverError: false
          },
          () => setTimeout(this.saveDisableTimeout.bind(this), 1000)
        );
      })
      .catch(() => {
        this.setState({ serverError: true });
      });
  }
    
  onChangeUserAttendance(e) {
    const { user_data } = { ...this.state };
    const currentState = user_data;
    const { name, checked } = e.target;
    currentState[name] = checked;

    this.setState({ user_data: currentState });
  }

  onChangeChildData(event) {
    const { user_data } = { ...this.state };
    const currentState = user_data;
    const { value, name } = event.target;
    const persnum = event.target.parentElement.parentElement.getAttribute('persnum');
    
    currentState["invitees"][persnum][name] = value;

    this.setState({ user_data: currentState });
  }

  onChangeChildData2(event) {
    const { user_data } = { ...this.state };
    const currentState = user_data;
    const { name, checked } = event.target;
    const persnum = event.target.parentElement.parentElement.parentElement.getAttribute('persnum');

    currentState["invitees"][persnum][name] = checked;

    this.setState({ user_data: currentState });
  }

  removeInvitee(index) {
    const { user_data } = { ...this.state };
    const currentState = user_data;

    currentState["invitees"].splice(index, 1);

    this.setState({ user_data: currentState });
  }

  addInvitee(event) {
    event.preventDefault();

    const { user_data } = { ...this.state };
    const currentState = user_data;
    const newPerson = {
      "name":"",
      "email":"",
      "food_kidsmenu":false,
      "food_veggie":false,
      "food_intolerances":false,
      "food_intolerancesdetail":"",
      "transport":""
    }

    currentState["invitees"].push(newPerson);

    this.setState({ user_data: currentState });
  }

  render() {
    const { formatMessage } = this.props.intl;
    const inviteesList = [];

    const handleCloseFailedLogin = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({login_tried : false});
    };

    const resetServerError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({serverError : false});
    }

    if(this.state.login_ok)
    {
      this.state.user_data.invitees.forEach( (element,index) => {
        inviteesList.push(
          <Persona intl={this.props.intl} key={index} persnum={`${index}`}
            persdata={JSON.stringify(element)}
            onChangeChildData={this.onChangeChildData}
            onChangeChildData2={this.onChangeChildData2}
            removeButtonAction={this.removeInvitee}
          />
        );
      });
    }

    return (
      <div className="guestarea">
        <p><FormattedMessage id="GuestArea.form.info" 
              defaultMessage="Help us organize the wedding. Confirm/decline here your attendance using the personal code we have given you."/>
        </p>
        {
          this.state.login_ok
          ?
          (
            <div>
            <hr/>
            <Snackbar open={this.state.savedisable}>
              <MuiAlert elevation={6} variant="filled" severity="success">
                {formatMessage({id:"GuestArea.alert.savedOk", defaultMessage:"Your data has been saved. Thank you!"})}
              </MuiAlert>
            </Snackbar>
            <form method="POST" action="/api" onSubmit={this.submitHandler_newData}>
              <p>
                <span><FormattedMessage id="GuestArea.form.helloopen" defaultMessage="¡Hola "/></span>
                <span style={{fontSize: '120%'}}>{this.state.user_desc}</span>
                <span><FormattedMessage id="GuestArea.form.helloclose" defaultMessage="!"/></span>
              </p>
              <ul style={{fontSize: '85%', textAlign: 'left'}}>
                <li><FormattedMessage id="GuestArea.form.instructions1" defaultMessage="Confirm if at least one person will attend."/></li>
                <li><FormattedMessage id="GuestArea.form.instructions2" defaultMessage="You can add guests by using the button 'Add Guest' at the bottom."/></li>
                <li><FormattedMessage id="GuestArea.form.instructions3" defaultMessage="You can reduce guests by using the [X] symbol beside each guest."/></li>
                <li><FormattedMessage id="GuestArea.form.instructions4" defaultMessage="Do not forget to save your data! You can find the 'Save' button at the end."/></li>
              </ul>
              <label>
                <FormattedMessage id="GuestArea.form.attend.text" defaultMessage="Shall we count on your attendance?"/>
              </label><br/>
                <Grid component="label" container alignItems="center" spacing={1} justify="center">
                  <Grid item>
                    <span style={{fontSize: '85%'}}><FormattedMessage id="GuestArea.form.attend.no"
                                    defaultMessage="Sadly not"/></span></Grid>
                  <Grid item>
                    <Switch
                      checked={this.state.user_data.attend===true} 
                      name="attend" 
                      color="primary"
                      onChange={this.onChangeUserAttendance}
                    />
                  </Grid>
                  <Grid item>
                    <span style={{fontSize: '85%'}}><FormattedMessage id="GuestArea.form.attend.yes"
                                    defaultMessage="Yes, I'm in!"/></span></Grid>
                </Grid>
                <br />
              { (this.state.user_data.attend===true) && inviteesList }
              { (this.state.user_data.attend===true) && (inviteesList.length < 10)
                ?
                  (<button onClick={this.addInvitee}>
                    <FormattedMessage id="GuestArea.form.addGuest" defaultMessage="Add Guest"/>
                  </button>)
                : null
              }
              <hr/>
              <button type="submit" disabled={this.state.savedisable}>
                { (this.state.savedisable)
                  ?
                    ( formatMessage({id:"GuestArea.form.saveDis", defaultMessage:"Thank you!"}) + " (" + this.state.saveDisableTime + ")" )
                  :
                    ( formatMessage({id:"GuestArea.form.save", defaultMessage:"Save"}) )
                }
              </button>
            </form>
            </div>
          )
          :
          (
            <div>
              <form method="POST" action="/api" onSubmit={this.submitHandler_login}>
                <div>
                <TextField
                  variant="outlined"
                  label={<FormattedMessage id="GuestArea.form.personalCode" defaultMessage="Personal Code"/>}
                  placeholder="ABC123"
                  name="user_uid"
                  onChange={this.onChange}
                  error={this.state.login_tried && !this.state.login_ok}
                  value={this.state.user_uid}
                  margin="normal"
                  inputProps={{ maxLength: 6 }}
                />
                </div>
                <div className="formstyle-1">
                <button type="submit">{formatMessage({id:"GuestArea.form.login", defaultMessage:"Login"})}</button>
                </div>
              </form>
            </div>
          )
        }
        <br/>
        <Snackbar open={this.state.login_tried && !this.state.login_ok} anchorOrigin={{vertical:'bottom',horizontal:'center'}} autoHideDuration={6000} onClose={handleCloseFailedLogin}>
          <MuiAlert elevation={6} variant="filled" severity="error" onClose={handleCloseFailedLogin}>
            {formatMessage({id:"GuestArea.form.wrongCode", defaultMessage:"Wrong code"})}
          </MuiAlert>
        </Snackbar>
        <Snackbar open={this.state.serverError} autoHideDuration={6000} onClose={resetServerError}>
          <MuiAlert elevation={6} variant="filled" severity="error">
            {formatMessage({id:"GuestArea.form.serverError", defaultMessage:"Server error"})}
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

export default injectIntl(GuestArea);
