import React, { PureComponent } from 'react';
import './Topimg.css';
import topimg from "./img/_MG_5357_webq2.JPG";

class Topimg extends PureComponent {
    render() {
        /* <img src={topimg}  className ="topimg-img" alt="bgimg"/>*/
        let styled = {
            backgroundImage: "url( "+topimg+")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center 35%"
        };
        return (
            <div className="topimg-div" style={styled}>
                <span className="topimg-overlay"></span>
            </div>
        )
    }
}

export default Topimg;
