import React, { Component, PureComponent } from 'react';
import './App.css';
import Countdown from './Countdown';
import Topimg from './Topimg';
import AppNavbarContainer from './Navbar';
import Orga from './Orga';
import GuestArea from './guestArea.js';
import {FormattedMessage, IntlProvider} from 'react-intl';
import MyTimeline from './timeline.js';
import Accommodation from './Accommodation'



class Intro extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <Countdown />
        </div>
        <div>
          <p>
            <FormattedMessage id="Orga.datetime" defaultMessage="21st August 2021, at 6:30 pm"/>
          </p>
          <MyTimeline />
        </div>
      </div>

    )
  }
}

class UnderConstruction extends PureComponent {
  render() {
    return (
      <div>
      <p>En construcción...</p>
      </div>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state={
      show: window.location.hash,
      lang: navigator.language.split(/[-_]/)[0],  // language without region code
    };

    this.messages = {
      'es': require("./translations/es.json"),
      'de': require("./translations/de.json")
    };

    this.setEsLocale = this.setEsLocale.bind(this);
    this.setDeLocale = this.setDeLocale.bind(this);
    this.setEnLocale = this.setEnLocale.bind(this);
  }

  handleHashChange = () => {
    this.setState({show: window.location.hash})
  }
  componentDidMount() {
    window.addEventListener('hashchange', this.handleHashChange);
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange);
  }

  setEsLocale() {
    this.setState({
      lang: 'es'
    })
  }
  setDeLocale() {
    this.setState({
      lang: 'de'
    })
  }
  setEnLocale() {
    this.setState({
      lang: 'en'
    })
  }

  render() {
    return (
      <IntlProvider locale={this.state.lang} messages={this.messages[this.state.lang]}>
      <div className="App">
        <section className ="App-topsection">
          <Topimg/>
          <header className="App-header">
              <h1 className="App-title">
                <FormattedMessage id="App.welcome" defaultMessage="Welcome to our wedding!"/>
              </h1>
          </header>
          <AppNavbarContainer/>
        </section>
        <section className="App-languagemenu">
          <button onClick={this.setEsLocale}><img src={require("./img/Spain.svg").default} alt="Español"/>ES</button>
          <button onClick={this.setDeLocale}><img src={require("./img/Germany.svg").default} alt="Deutsch"/>DE</button>
          <button onClick={this.setEnLocale}><img src={require("./img/United.svg").default} alt="English"/>EN</button>
        </section>
        <section>
          <div className="App-content" id='content'>
              {this.state.show===''        && <Intro />}
              {this.state.show==='#Inicio' && <Intro />}
              {this.state.show==='#Organ'  && <Orga />}
              {this.state.show==='#GuestArea' && <GuestArea />}
              {this.state.show==='#Fotos' && <UnderConstruction />}
              {this.state.show==='#Accommodation' && <Accommodation />}
          </div>
        </section>
        <section className="Sectionflex">
        </section>
        <section>
          <div className="App-footer">
            <p>
              &copy; <FormattedMessage id="App.footer" defaultMessage="2021 by Dani & Anais. With love for our guests."/>
            </p>
          </div>
        </section>
      </div>
      </IntlProvider>
    );
  }
}

export default App;
