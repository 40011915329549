import React, { PureComponent } from 'react';
import './App.css';
import {FormattedMessage, injectIntl} from 'react-intl';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';



const mytheme = createMuiTheme ({
  typography: {
    body1: {
      'font-family': 'inherit',
    },
  },
  overrides: {
    MuiTimelineContent: {
      root: {
        'text-align': 'initial',
      }
    },
  }
});

class MyTimeline extends PureComponent {
  render() {
    return (
    <ThemeProvider theme={mytheme}>
      <Timeline>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">18:30</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography><FormattedMessage id='Timeline.welcome' defaultMessage='Greeting'></FormattedMessage></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">19:00</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography><FormattedMessage id='Timeline.ceremony' defaultMessage='Ceremony'></FormattedMessage></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">20:00</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography><FormattedMessage id='Timeline.cocktail' defaultMessage='Reception'></FormattedMessage></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">21:30</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
           <Typography><FormattedMessage id='Timeline.dinner' defaultMessage='Dinner'></FormattedMessage></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">0:00</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
           <Typography><FormattedMessage id='Timeline.clubparty' defaultMessage='Club party'></FormattedMessage></Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">3:30</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent>
           <Typography><FormattedMessage id='Timeline.goodbye' defaultMessage='Good bye'></FormattedMessage></Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </ThemeProvider>
    );
  }
}

export default injectIntl(MyTimeline);
