import React, { Component, PureComponent } from 'react';
import './Accommodation.css';
import {injectIntl, FormattedMessage} from 'react-intl';


class Accomodation extends PureComponent {
  render() {
    return(
      <div className ="section-accom">
        <div className="accom-wh">
          <div className="accom-wh-question">
            <p>
              <FormattedMessage id="Accommodation.0" defaultMessage="Need a room?" />
            </p>
          </div>
          <div className="accom-wh-data">
            <p>
            <FormattedMessage id="Accommodation.1" defaultMessage="If you need some accomodation, we suggest to look for it in the city center. It is very well communicated and ideal for sightseeing in the city. The following spots would be in our opinion some of the best!" />
            </p>
            <ul>
              <li>Puerta del Sol</li>
              <li>Gran vía</li>
              <li>Tribunal</li>
              <li>Plaza de Santa Ana</li>
            </ul>
            <p>
            <FormattedMessage id="Accommodation.2" defaultMessage="Following you can find some recommendations for hotels and apartments of different categories (please, be aware that we do not have any agreement with them). " />
            {/* <FormattedMessage id="Accommodation.2b" defaultMessage="If you have any question or doubt, please do not hesitate to contact us :)" /> */}
            </p>
            <br/>
          </div>
          <div className="Accommodation-map">
            <iframe src="https://www.google.de/maps/d/embed?mid=1o3_rcLEoXY_JqAKmSzRPgxhBdaIg9oZv" ></iframe>
          </div>
          <div className="accom-wh-data">
            <p>
              <FormattedMessage id="Accommodation.3" defaultMessage="If you have any question or doubt, please do not hesitate to contact us 🙂" />
            </p>
          </div>
        </div>
      </div>
    )
  }
}


export default injectIntl(Accomodation);
