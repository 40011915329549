import React, { PureComponent } from 'react';
import './Orga.css';
import {injectIntl, FormattedMessage} from 'react-intl';
import WedMap from './WedMap';


class Orga extends PureComponent {
  render() {
    return(
      <div className="section-orga">
        <div className="orga-savethedate">
          <FormattedMessage id="Orga.savethedate" defaultMessage="Save the date!"/>
        </div>
        <div className="orga-wh">
          <div className="orga-wh-question">
            <FormattedMessage id="Orga.when" defaultMessage="When?"/>
          </div>
          <div className="orga-wh-data">
            <FormattedMessage id="Orga.datetime" defaultMessage="21st August 2021, at 6:30 pm"/>
          </div>
        </div>
        <div className="orga-wh">
          <div className="orga-wh-question">
            <FormattedMessage id="Orga.where" defaultMessage="Where?"/>
          </div>
          <div className="orga-wh-data">
            <FormattedMessage id="Orga.eventlocationname" defaultMessage="Club de tiro de Madrid"/><br/>
            <FormattedMessage id="Orga.eventlocationaddress" defaultMessage="Ctra. Madrid-El Pardo, km 1.2, 28048 Madrid"/>
          </div>
        </div>
        <div className="orga-wh">
          <div className="orga-wh-question">
            <FormattedMessage id="Orga.getthere" defaultMessage="How to get there?"/>
          </div>
          <div className="orga-wh-data">
            <p><FormattedMessage id="Orga.arrive1" defaultMessage="You can either get there on your means (private car, taxi, Uber, ...) or with one of the coaches that we will rent for our guests. Take a look at the map below to have an overview about the location and the busses."/></p>
            <p><FormattedMessage id="Orga.arrive2" defaultMessage="If you arrive with your private car, there is a free and surveilled parking space exclusively available for our guests."/></p>
            <p><FormattedMessage id="Orga.arrive3" defaultMessage="If you prefer to use one of the shuttles, please indicate it in the Guest Area."/></p>
            <p><FormattedMessage id="Orga.arrive.bus1.header" defaultMessage="Bus option 1: Torrejón de Ardoz - Hortaleza - Club de Tiro"/></p>
            <ul>
              <li><FormattedMessage id="Orga.arrive.bus1.dep" defaultMessage="Departs: 5:45 pm Torrejón de Ardoz"/></li>
              <li><FormattedMessage id="Orga.arrive.bus1.stop" defaultMessage="Stops at: Metro Station Mar de Cristal "/></li>
              <li><FormattedMessage id="Orga.arrive.bus1.ret" defaultMessage="Returns: 3:30 am Club de Tiro"/></li>
            </ul>
            <p><FormattedMessage id="Orga.arrive.bus2.header" defaultMessage="Bus option 2: Madrid (city center) - Club de Tiro"/></p>
            <ul>
              <li><FormattedMessage id="Orga.arrive.bus2.dep" defaultMessage="Departs: 6:00 pm Plaza de España"/></li>
              <li><FormattedMessage id="Orga.arrive.bus2.ret" defaultMessage="Returns: 3:30 am Club de Tiro"/></li>
            </ul>
          </div>
        </div>
        <div className="orga-wh" style={{width: "90%", margin: "0 auto"}}>
          <div className="orga-wh-question">
            <FormattedMessage id="Orga.maptitle" defaultMessage="Map"/>
          </div>
          <WedMap />
        </div>
      </div>
    )
  }
}

export default injectIntl(Orga);
