import React, { PureComponent } from 'react';
import './Countdown.css';
import {injectIntl} from 'react-intl';

/*
			ref: new Date(1599931800000),
			ref_year:   2020,
			ref_month:  8, // From 0 to 11
			ref_day:    12,
			ref_hour:   17, // UTC
			ref_minute: 30,
			* */

const ref = new Date(1629563400000);
//const ref_year=   2020;
const ref_month=  7; // From 0 to 11
const ref_day=  21;
const ref_hour=   18; // UTC+2
const ref_minute= 30;

class Ss extends PureComponent {
	render() {
		return (
			<React.Fragment>
				<div className="Countdown-section">
					<span className="Countdown-numbers Countdown-numbers-anim">{('0' + this.props.value).slice(-2)}</span>
					<span className="Countdown-letters">{this.props.value === 1 ? this.props.text1 : this.props.text2}</span>
				</div>
			</React.Fragment>
		);
	}
}

class Ss2 extends PureComponent {	
	constructor(props) {
		super(props)
		this.state = {
			
		}
	}
	
	static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps.value !== prevState.value)
			return {
				value: nextProps.value,
				anim: "Countdown-numbers-anim"
			}
		else
			return null;
	}
	
	removeAnimation() {
		//((this.props.text2==="minutes") && console.log(`removeAnimation minutes`));
		this.setState({ 
			anim : ""
		});
	}
	
	componentDidMount() {
		//((this.props.text2==="minutes") && console.log("componentDidMount minutes ("+this.props.value+")"));
		if(this.state.anim !== "") {
			//((this.props.text2==="minutes") && console.log("state.anim minutes: "+this.state.anim));
			this.interval2 = setTimeout(this.removeAnimation.bind(this), 5000);
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		//((this.props.text2==="minutes") && console.log(`componentDidUpdate: ${this.props.text2}`));
		
		// If the value has been changed, set a timeout to remove the animation
		if( prevProps.value !== this.props.value )
		{
			this.componentDidMount();
		}
	}

	componentWillUnmount() {
		//((this.props.text2==="minutes") && console.log(`componentWillUnmount: ${this.props.text2}`));
		clearInterval(this.interval2);
	}
	
	render() {
		//((this.props.text2==="minutes") && console.log(`Anim ${this.props.text2}: ${this.state.anim}`));
		return (
			<React.Fragment>
				<div className="Countdown-section">
					<span className={`Countdown-numbers ${this.state.anim}`}>{('0' + this.props.value).slice(-2)}</span>
					<span className="Countdown-letters">{this.props.value === 1 ? this.props.text1 : this.props.text2}</span>
				</div>
			</React.Fragment>
		);
	}
}

class Countdown extends PureComponent {
	constructor() {
		super()
		this.state = {
			months:  0,
			days:    0,
			hours:   0,
			minutes: 0,
			seconds: 0,
			anim: "Countdown-numbers"
		}
	}
	
	diffmonth(currMonth, currDay, currHour, currMin) {
		let val = 0;
		if(currMonth > ref_month)
		{
			val = ref_month - currMonth + 12;
		}
		else
		{
			val = ref_month - currMonth;
		}

		if(currDay > ref_day)
		{
			val -= 1;
		}
		else if( currDay === ref_day )
		{
			if( currHour > ref_hour )
			{
				val -= 1;
			}
			else if( (currHour === ref_hour) && (currMin >= ref_minute) )
			{
				val -= 1;
			}
		}

		return val;
	}
	diffday(currDay, currMonth, currHour, currMin) {
		let val=0;
		let rollover = 0;

		switch(currMonth) {
			case 0:
			case 2:
			case 4:
			case 6:
			case 7:
			case 9:
			case 11:
			{
				rollover = 31;
				break;
			}
			case 1:
			{
				rollover = 29; // 2020 Bisiesto
				break;
			}
			default:
			{
				rollover = 30;
				break;
			}
		}

		if(currDay > ref_day) {
			val = rollover - currDay + ref_day;
		}
		else {
			val = ref_day - currDay;
		}
		
		if((currHour > ref_hour) ||
			((currHour === ref_hour) && (currMin >= ref_minute)))
		{
			val = val - 1;
		}

		if(val < 0)
		{
			val = rollover - 1;
		}
		
		return val;
	}
	diffhour(currHour, currMin) {
		let val = ref_hour - currHour;
		
		if(currMin >= ref_minute)
			val = val - 1;
			
		if(val < 0)
			val = val+ 24;
			
		return val;
	}
	diffmin(currMin) {
		if(currMin >= ref_minute)
			return ref_minute - currMin - 1 + 60;
		else
			return ref_minute - currMin - 1;
	}
	
	myf() {
		let newDate 	= new Date();
		let newMonth 	= newDate.getMonth();
		let newDateDay 	= newDate.getDate();
		let newHours 	= newDate.getHours();
		let newMinutes 	= newDate.getMinutes();
		let newSeconds 	= newDate.getSeconds();

		if(newDate.valueOf() <= ref)
		{
			this.setState({
				months:  this.diffmonth( newMonth, newDateDay, newHours, newMinutes ),
				days:    this.diffday( newDateDay, newMonth, newHours, newMinutes ),
				hours:   this.diffhour( newHours, newMinutes ),
				minutes: this.diffmin( newMinutes ),
				seconds: 59 - newSeconds,
				anim_seconds: "" 
			});
		}
		else
		{

		}
	}

	componentDidMount() {
		this.interval = setInterval(this.myf.bind(this), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const { formatMessage } = this.props.intl;
		return (
		<div className="Countdown">

			<Ss2 text1={formatMessage({id:"Countdown.month",
									defaultMessage:"month" })}
				 text2={formatMessage({id:"Countdown.months",
									defaultMessage:"months" })}
				 value={this.state.months}/>

			<Ss2 text1={formatMessage({id:"Countdown.day",
									defaultMessage:"day" })}
				 text2={formatMessage({id:"Countdown.days",
				 					defaultMessage:"days" })}
				 value={this.state.days}/>

			<Ss2 text1={formatMessage({id:"Countdown.hour",
									defaultMessage:"hour" })}
				 text2={formatMessage({id:"Countdown.hours",
									defaultMessage:"hours" })}
				 value={this.state.hours}/>

			<Ss2 text1={formatMessage({id:"Countdown.minute",
									defaultMessage:"minute" })} 
				 text2={formatMessage({id:"Countdown.minutes",
				 					defaultMessage:"minutes" })}
				 value={this.state.minutes}/>

			<Ss text1={formatMessage({id:"Countdown.second",
									defaultMessage:"second" })}
				text2={formatMessage({id:"Countdown.seconds",
									defaultMessage:"seconds" })}
				value={this.state.seconds}/>

		</div>
		);
	}
}

export default injectIntl(Countdown);
